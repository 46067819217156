export default {
  data() {
    return {
      isMobile: false,
    }
  },
  mounted() {
    this.checkIsMobile()
    window.addEventListener('resize', this.checkIsMobile, true)
  },
  methods: {
    checkIsMobile() {
      const { innerWidth } = window
      this.isMobile = innerWidth >= 320 && innerWidth <= 500
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIsMobile, true)
  },
}

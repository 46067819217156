<template>
  <div class="modal-backdrop" @click="handleClose">
    <div class="modal">
      <div class="tiyan-wrapper">
        <slot>
          <svg-icon url="tiyan" class-name="tiyan"></svg-icon>
          <div class="tips">打开微信扫码体验</div>
        </slot>
      </div>
      <div class="vertical-line"></div>
      <svg-icon icon-class="close" class-name="close" @click="handleClose"></svg-icon>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {},
  data() {
    return {}
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="less" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  .modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin: 0 auto;
  }
  .tiyan-wrapper {
    position: relative;
    text-align: center;
  }
  .tips {
    position: absolute;
    font-size: 24px;
    font-family: AlibabaPuHuiTiR;
    color: #000000;
    line-height: 33px;
    bottom: 36px;
    width: 100%;
  }
  .tiyan {
    width: 282px;
    height: 332px;
  }
  .vertical-line {
    width: 1px;
    height: 25px;
    position: relative;
    margin: 0 auto;
    opacity: 0.7;
    background: #ffffff;
  }
  .close {
    padding: 14px;
    font-size: 18px;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid #ffffff;
    border-radius: 50%;
    opacity: 0.7;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>

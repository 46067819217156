<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import homeApi from '@/api/home.api.js'
import dayjs from 'dayjs'
export default {
  mounted() {
    // const u = 'pages/index/index?refId=popularize_202209210756670202'
    // const refId = u.split('refId=')[1]
    const refId = location.href.split('refId=')[1]
    if (location.href.includes('refId=')) {
      this.batchSave(refId)
    }
  },
  methods: {
    batchSave(refId) {
      homeApi.batchSave([
        {
          apptype: 3,
          refId,
          triggertime: dayjs().format(),
          channel: '',
          companyId: '',
          companyName: '',
          createtime: '',
          datatype: '',
          deviceheight: '',
          devicetype: '',
          devicewidth: '',
          functioncode: 1217,
          functionname: '',
          id: '',
          imei: '',
          networktype: '',
          osname: '',
          pagecode: '',
          pageurl: '',
          phonenumber: '',
          platform: '',
          position: '',
          resource: '',
          sceneId: '',
          traceid: '',
          userId: '',
        },
      ])
    },
  },
}
</script>

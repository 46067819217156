<template>
  <vuescroll :ops="ops" ref="vs">
    <slot></slot>
  </vuescroll>
</template>

<script>
import vuescroll from 'vuescroll'
export default {
  name: 'VueScroll',
  provide() {
    return {
      getPosition: this.getPosition,
      scrollTo: this.scrollTo,
    }
  },
  components: {
    vuescroll,
  },
  props: {
    scrollingX: {
      type: Boolean,
      default: true,
    },
    scrollingY: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  watch: {},
  data() {
    return {
      ops: {
        vuescroll: {
          // wheelScrollDuration: 300,
          detectResize: false,
        },
        scrollPanel: {
          // easing: 'easeOutCubic',
          scrollingX: this.scrollingX,
          scrollingY: this.scrollingY,
        },
        rail: {},
        bar: {
          background: 'rgba(120, 120, 120, 0.4)',
        },
      },
    }
  },
  methods: {
    getPosition() {
      const { scrollTop, scrollLeft } = this.$refs['vs'].getPosition()
      return { scrollTop, scrollLeft }
    },
    scrollBy(params = {}, speed = 500, type = undefined) {
      this.$refs.vs.scrollBy(params, speed, type)
    },
    scrollTo(params = {}, speed = 500, type = undefined) {
      console.log(params)
      this.$refs.vs.scrollTo(params, speed, type)
    },
  },
}
</script>

import { render, staticRenderFns } from "./PortalLayout.vue?vue&type=template&id=741b3be8&scoped=true&"
import script from "./PortalLayout.vue?vue&type=script&lang=js&"
export * from "./PortalLayout.vue?vue&type=script&lang=js&"
import style0 from "./PortalLayout.vue?vue&type=style&index=0&id=741b3be8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "741b3be8",
  null
  
)

export default component.exports
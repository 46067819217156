<template>
  <div>
    <!-- <Navgation /> -->
    <div class="page-content">
      <vue-scroll>
        <router-view />
        <Footer />
        <Side v-if="!isMobile" />
      </vue-scroll>
    </div>
  </div>
</template>

<script>
import Side from '@/components/side'
import Footer from '@/layouts/components/Footer'
import Navgation from '@/layouts/components/Navgation'
import VueScroll from '@/components/scroll'
import checkIsMobile from '@/mixin/checkIsMobile'
export default {
  name: 'PortalLayout',
  mixins: [checkIsMobile],
  components: { Footer, VueScroll, Side },
}
</script>
<style lang="less" scoped>
.page-content {
  width: 100%;
  overflow: hidden;
  height: calc(100vh);
}
@media screen and (max-width: 768px) {
  .page-content {
    height: calc(100vh - 0);
  }
}
</style>

import axios from 'axios'

// import router from '@/router/router.js'
import auth from '@/utils/auth.js'

export const http = axios.create()
http.defaults.timeout = 20000

export const sjsgw = '/sjsgw'

// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    // config.defaults.headers.common[
    //   'Authorization'
    // ] = `Bearer ${localStorage.getItem('access_token')}`
    let url = config.url
    // get参数编码
    if (config.method === 'get' && config.params) {
      // 此步骤用以转义get请求中URL中的特殊字符，否则请求发送失败
      url += '?'
      let keys = Object.keys(config.params)
      for (let key of keys) {
        url += `${key}=${encodeURIComponent(config.params[key])}&`
      }
      url = url.substring(0, url.length - 1)
      config.params = {}
    }
    config.url = url
    // config.headers['Business-Name'] = 'request'
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => {
    // 接下来会在这里进行token过期的逻辑处理
    if (response.data.code !== '0') {
      auth.removeAccessToken()
      auth.removeLoginId()
      document.cookie = 'access_token='
    }
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

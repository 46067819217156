<template>
  <svg v-if="url === ''" :class="svgClass" aria-hidden="true" v-on="$listeners">
    <use :xlink:href="iconName" />
  </svg>
  <img v-else :src="iconUrl" :class="imgClass" v-on="$listeners" />
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    // icon 名称
    iconClass: {
      type: String,
      default: '',
    },
    // 样式名称
    className: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`
    },
    // class 样式名称
    svgClass() {
      return 'svg-icon ' + this.className
    },
    iconUrl() {
      return require(`@/assets/images/${this.url}.png`)
    },
    imgClass() {
      return 'img-responsive ' + this.className
    },
  },
}
</script>
<style scoped lang="less">
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>

const saveLoginId = (val) => {
  localStorage.setItem('login-id', JSON.stringify(val))
}

const getLoginId = () => {
  return JSON.parse(localStorage.getItem('login-id'))
}

const removeLoginId = () => {
  localStorage.removeItem('login-id')
}

const saveAccessToken = (val) => {
  localStorage.setItem('access_token', JSON.stringify(val))
}

const getAccessToken = () => {
  return JSON.parse(localStorage.getItem('access_token'))
}

const removeAccessToken = () => {
  localStorage.removeItem('access_token')
}

export default {
  saveLoginId,
  getLoginId,
  removeLoginId,
  saveAccessToken,
  getAccessToken,
  removeAccessToken,
}
